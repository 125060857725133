.acntCheck {
    width: inherit !important;
    height: inherit !important;
}

.item-box-detail {
    width: 50%;
}

.hide-shipping-item {
    display: none;
}

.payment-div {
    padding-top: 20px;
    height: 300px;
    width: 300px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
  }

  .error {
    color:#db2269;
    font-size: 1em;
    display: relative;
  }

  input {
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #d6d1d5;
  }

  input::placeholder {
    font-size: 1em;
    font-weight: lighter;
    color: #bbb;
  }
