.form-steps{
  margin-bottom:10px;
}

.form-steps h4 {
  margin-bottom:6px;
  color:#333;
  border-bottom: 1px solid #333;
  padding-bottom: 5px;
}

.shipping-detail-box{
  margin-top:20px;
}

.continue-btn button {
  border-radius: 20px;
  padding: 8px 20px;
}

  