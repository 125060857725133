/*
 * Loader css File
*/

.loader {    
    width: 200px;    
    z-index: 9999;
    margin: 'auto';
    display: 'block';
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
}

.loader-bg{
    z-index: 99999;
    background: #fbfbfb;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}