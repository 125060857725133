/*
 * Topbar css File
*/


.w3l-top-header-strip{
  box-shadow: 1px 1px 1px 1px #e3e3e3;}

/* .selectCn .css-yk16xz-control,.selectCn .ccs-1pahdxg-control{
    background-color: transparent;
    border: 0;
}
.selectCn .css-g1d714-ValueContainer{
    background-color: #384d62;
    outline: none;
    border: 1px solid #384d62;
    border-top: 0;
    border-bottom: 0;
}

.selectCn>div:focus{
    outline: none;
}
.selectCn>div>div>div>div>div>span {
    color: #fff;
}
.selectCn>div>div:last-child{
     margin: 0 !important;
    color: #ffffff !important;
    background: #384d62;
}
.selectCn>div>div{
    border: none !important;
    outline: none;
    padding: 0 !important;

 }
.selectCn>div>div>div ~ div{
  display: none;
} */
.myAccount .accountIcon{
  border-radius: 100%;
    border: 1px solid;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    background-color: #b6b6b6;
    border-color: #fff;
    color: #fff;
}
.topStrip .navbar-brand{
    padding: 0;
}

.loggedInDropDown {
    position: relative;
    display: inline-block;
  }
  .p20{
    padding: 20px 0;
  }
  .loggedInDropDown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 5;
    top: 40px;
    width: 100%;
    right: -20px;
  }

  .loggedInDropDown:hover .loggedInDropDown-content {
    display: block;
  }
  .loggedInDropDown ul li a{
      border-right: 0 !important;
      padding: 0 !important;
  }
  .loggedInDropDown ul li{
        padding: 5px 10px;
        border-bottom: 1px solid #ebebeb;
  }
.addToCart{
  background-color: #f4ca38;
    border-radius: 5px;
    padding: 5px 20px;
    color: #fff;
    display: flex;
    align-items: center;
}
.myAccount{
  padding: 5px 20px;
}
.itemsCount{
  position: absolute;
    top: -5px;
    background-color: red;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -5px;
    font-weight: normal;
    font-size: 13px;
    min-width: max-content;
}
.totalSubtotal{
  color: #384d62 !important;
  /* background-color: rgba(221, 221, 221, 1); */

}
.signiSignUp{
  top:45px !important;
  width: 150px;
}
.signiSignUp ul li a{
  color: #384d62;
  cursor: pointer;
}
.signiSignUp ul li:hover{
 background-color: #dfdfdf;
}


/* On screens that are 1024px or less */
@media (min-width: 992px) and (max-width: 1024px) {
  #megaMenu>li>a{
  height: 100%;
}
.topStrip .brand{
  display: none;
}
}

@media screen and (max-width: 768px) {
    #megaMenu{
        flex-direction: column !important;
      }
      #megaMenu ul{
        flex-direction: column;
      }
      .mob-bars{
        order: -1;
      }
      .mob-bars .nav{
        padding: 0;
      }
      .topStrip>div, .topStrip>header{
        width: 100%;
      }
      .carousel-slider,.w3l-main-slider .banner-view{
        min-height: max-content;
      }
      .carousel-slider{
        height: max-content !important;
      }

  }