.topBanners img{
    max-height: 250px;
    min-height: 250px;
    object-fit: cover;
}
.thisPageLink{
    margin-top: -25px;
    margin-left: 10px;
}

.product-container{
    padding: 0 15px;
    
}


.sorting{
    appearance: revert;
    -webkit-appearance: revert;
}

.product-name-box {
    min-height: 60px;
    width: 100%;
    
}

.pNameEllipsis1{
    word-wrap: break-word;
}