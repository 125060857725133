.submenu-list{
    position: absolute;
    right: -200px;
    min-width: 200px;
    top: 0;
    z-index: 1;
    background: #fff;
    border: 2px solid #666;
    display:none;
}
.productlist li.subcategory:hover ul.submenu-list {
    display: block;
}
.productlist li.subcategory ul.submenu-list li.nav-item{
    padding: 0;
}
.productlist li.subcategory ul.submenu-list li a:hover{
    color:#fff;
}
.productlist li.subcategory ul.submenu-list li.nav-item:hover{
    background-color:#ffc246;
}
.productlist li:hover {
    background-color: #ffc246;
  }
  .side-menu-list .menu-list .active > a {
    background-color: #ffc246;
}
.productlist {
    background-color: #fff; /* Set the default background color */
  }
  
  .productlist .active {
    background-color: #ffc246;
  }
  