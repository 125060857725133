.selectCn .css-yk16xz-control,.selectCn .ccs-1pahdxg-control{
    background-color: transparent;
    border: 0;
}
.selectCn .css-g1d714-ValueContainer{
    background-color: #384d62;
    outline: none;
    border: 1px solid #384d62;
    border-top: 0;
    border-bottom: 0;
}

.selectCn>div:focus{
    outline: none;
}
.selectCn>div>div>div>div>div>span {
    color: #fff;
}
.selectCn>div>div:last-child{
     margin: 0 !important;
    color: #ffffff !important;
    background: #384d62;
}
.selectCn>div>div{
    border: none !important;
    outline: none;
    padding: 0 !important;

 }
.selectCn>div>div>div ~ div{
  display: none;
}