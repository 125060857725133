.loggedInDropDown-content {
    min-width: 300px;
}

.loggedInDropDown-content i.fa.fa-times {
    position: absolute;
    right: 1px;
    top: 20px;
}

.item-img img {
    width: 75px;
}

.item-cont {
    width: 70%;
}

.ItemBox {
    position: relative;
}

#shoppingCart {
    max-width: max-content;
}

.cartQuickView a {
    color: darkblue;
    font-weight: 600;
}

.ItemBox i {
    cursor: pointer;
}

.ItemBox i:hover {
    color: red;
}

.white-border-bottom {
    border-bottom: 1px solid #f3f3f3;
}

.bg-light-blue {
    background-color: rgb(237, 242, 254, 1);
}

.cartContainer {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
}

.CartItems i {
    color: #000;
}

.CartItems i:hover {
    color: rgb(62, 116, 241);
    ;
}

.cartQuickView {
    max-height: 400px;
    overflow-y: auto;
}