#cad-view {
    display: inline-block;
    margin: 10px 0;   
    
    /*			border: 1px solid #666;*/
}
#cad-view > p{
    margin: 100px 10px;
    color:#ffffff;  
}
#cad-view > canvas {
    border: 1px solid #666;
    background-color: black;
}
table tr{
    border-top:1px solid #12eb12 ;
    border-bottom:1px solid #12eb12 ;
}
#cad-view{
    margin-top: 0;
}
.dxfFileName{
    background-color: purple;
    color:#fff;
    text-align: center;
}
.divRow{
    display: flex;    
    background-color: #fff;

}
.divRow .divCol{
    padding: 10px;    
    width: 100%;
    border: 1px solid #ededed;
    
}
.divTable{
    background-color: #384d62;
    margin-bottom: 50px;
    border-radius: 10px;
    overflow: hidden;
}
.divTable h4{
    color: #fff;
}
.divCaption{
    background-color: transparent  !important;
    padding: 10px 20px;
}
