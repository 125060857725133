.topBanners img{
    max-height: 250px;
    min-height: 250px;
    object-fit: cover;
}
.CategoryName{
    position: absolute;
    top: 9px;
    background-color: #384d62;
    color: #fff;
    border-radius: 0;
    padding: 5px;
    font-family: sans-serif;
}
