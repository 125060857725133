ul.serviceContainerList li a img{
    width: 50px;
    height: 50px;
    object-fit: contain;
    background-color: #ebdddd;
    border-radius: 10px;
}
.serviceContainerList li{
    margin-bottom: 10px;
}

.serviceContainerList li a{
    display: flex;
    align-items: center;
}