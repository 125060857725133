/*
 * Product  Details css File
*/

/* .Product {

} */

.sliderImage{
    width: 469.156px;
    margin-right: 0px;
}

.sliderTransition{
    transition-duration: 400ms;
    transition-timing-function: ease;
}

.lsGrabElementEffc{
    width: 7975.65px;
    transform: translate3d(-1407.47px, 0px, 0px);
    height: 469.141px;
    padding-bottom: 0%;
}
.lsSliderGallery{
    margin-top: 5px;
    transition-duration: 400ms;
    width: 790.76px;
    transform: translate3d(0px, 0px, 0px);
}


.bottomImgCss{
    width:100%;width:47.684px;margin-right:5px
}
.stock-availablity-box {
    justify-content: space-between;
    align-items: center;
}

.stock-price-box .in-stock{
    color: #f4ca38;
    font-size: 18px;
}
.stock-price-box p{
    font-size: 20px;
    margin: 10px 0 0;
}
.stock-price-box p span{
    font-size: 16px;
}
.productQtyCartBuy .form-group select{
    appearance: revert;
    -webkit-appearance: revert;
}
