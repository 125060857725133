/*
 * Header css File
*/

nav ul, nav li {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav a {
  display: block;
  text-decoration: none;
}

nav a:hover, nav a:visited {
  text-decoration: none;
}

.breadcrumb li a {
  display: inline-block;
}

.menu-bar {
  background: #ffffff;
  display: flex;
}

#megaMenu>li>a {
  background: #384d62;
  color: #ffffff;
  height: 100%;
}

.menu-link {
  padding: 15px 10px;
  background: #ffffff;
  color: #384d62;
  transition: background 0.2s, color 0.2s;
  position: relative;
  z-index: 1;
  font-weight: 500;
}

.menu-link[aria-haspopup="true"] {
  padding-right: 40px;
}

.menu-link[aria-haspopup="true"]:after {
  content: "";
  background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowRight.svg#accent');
  background-size: 14px;
  width: 14px;
  height: 14px;
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.mega-menu-header {
  font-size: 1.1em;
  text-transform: uppercase;
  font-weight: bold;
  color: #384d62;
}

.mega-menu {
  background: #ffffff;
  z-index: 10;
}

.mega-menu--multiLevel {
  flex-direction: column;
}

.mega-menu {
  border: 1px solid #efefef;
  border: 1px solid #efefef;
  box-shadow: 1px 1px 1px 1px #fafafa;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

@media all and (min-width: 951px) {
  .nav {
    background: #ffffff;
  }
  .nav>nav {
    max-width: 900px;
    margin: 0 auto;
  }
  .menu [aria-haspopup="true"]~ul {
    display: none;
  }
  .menu-bar {
    position: relative;
  }
  .menu-bar>li>[aria-haspopup="true"]:after {
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowBottom.svg#light');
  }
  .menu-bar>li>[aria-haspopup="true"]:hover:after {
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowBottom.svg#light');
  }
  .menu-bar>li>[aria-haspopup="true"]:hover~ul {
    display: flex;
    transform-origin: top;
    animation: dropdown 0.2s ease-out;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul:hover {
    display: flex;
  }
  .menu-bar>li:hover>[aria-haspopup="true"]~ul {
    display: flex;
  }
  .menu-bar>li>[aria-haspopup="true"]:hover, .menu-bar>li:hover>a {
    background: #ffc246;
    color: #ffffff;
  }
  .menu-bar>li>[aria-haspopup="true"]:hover:after, .menu-bar>li:hover>a:after {
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowTop.svg#light');
  }
  .mega-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
  .mega-menu:hover {
    display: flex;
  }
  .mega-menu a:hover {
    background: #ffc246;
    color: #ffffff;
  }
  .mega-menu--multiLevel>li {
    width: 33.33333333%;
  }
  .mega-menu--multiLevel>li>[aria-haspopup="true"]~ul {
    left: 33.33333333%;
    width: 33.33333333%;
  }
  .mega-menu--multiLevel>li>[aria-haspopup="true"]~ul ul {
    width: 100%;
    left: 100%;
  }
  .mega-menu--multiLevel li:hover>[aria-haspopup="true"]~ul {
    display: block;
    transform-origin: left;
    animation: flyout 0.2s ease-out;
  }
  .mega-menu--multiLevel li:hover>[aria-haspopup="true"]~ul {
    display: block;
  }
  .mega-menu--multiLevel li:hover>[aria-haspopup="true"], .mega-menu--multiLevel li:hover>a {
    background: #ffc246;
    color: #ffffff;
  }
  .mega-menu--multiLevel [aria-haspopup="true"]~ul, .mega-menu--multiLevel [aria-haspopup="true"] {
    border-left: 1px solid #f0f0f0;
  }
  .mega-menu--multiLevel [aria-haspopup="true"]~ul:hover, .mega-menu--multiLevel [aria-haspopup="true"]:hover {
    display: block;
  }
  .mega-menu--multiLevel [aria-haspopup="true"]~ul {
    position: absolute;
    top: 0;
    height: 100%;
  }
  .mega-menu--flat>* {
    flex: 1;
  }
  .mobile-menu-trigger, .mobile-menu-header, .mobile-menu-back-item {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1266px) {
  .brand .navbar-brand img {
    width: 100px;
  }
  .myAccount {
    padding: 5px;
  }
  #megaMenu>li>a {
    padding: 17px 10px;
    font-size: 14px;
  }
  .menu-link[aria-haspopup="true"]:after {
    right: -3px;
  }
}

@media all and (max-width: 1024px) {
  .mob-bars {
    order: -1;
  }
  #megaMenu {
    flex-direction: column !important;
  }
  .nav {
    padding: 20px;
  }
  .mobile-menu-trigger, .mobile-menu-header, .mobile-menu-back-item {
    display: block;
  }
  .mobile-menu-trigger {
    background: #384d62;
    color: #ffffff;
    border: 0;
    padding: 10px;
    font-size: 1.2em;
    border-radius: 4px;
  }
  .mobile-menu-header {
    order: -1;
    background: grey;
  }
  .mobile-menu-header a {
    padding: 20px 25px;
    color: #ffffff;
    visibility: visible;
  }
  .menu-bar {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 350px;
    max-width: 350px;
    max-width: 90%;
    overflow-x: hidden;
    transition: left 0.3s;
    box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
    z-index: 9999;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    position: absolute;
    left: 100%;
    top: 0;
    max-height: 100vh;
    width: 100%;
    transition: left 0.3s;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul>li>[aria-haspopup="true"] {
    font-size: 1.2em;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul>li>[aria-haspopup="true"]~ul a {
    padding-left: 40px;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul>li>[aria-haspopup="true"]~ul>li>[aria-haspopup="true"]~ul a {
    padding-left: 80px;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul [aria-haspopup="true"] {
    color: #2a2a2a;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul [aria-haspopup="true"]:after {
    content: "+";
    background: none;
    font-size: 1em;
    font-weight: normal;
    height: 20px;
    line-height: 1;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul [aria-haspopup="true"]~ul {
    max-height: 0px;
    transform-origin: top;
    transform: scaleY(0);
    transition: max-height 0.1s;
  }
  .mega-menu-content {
    padding: 20px 25px;
  }
  .mobile-menu-back-item {
    order: -1;
  }
  .mobile-menu-back-item a {
    background: #d9d9d9;
    color: #2a2a2a;
    max-height: calc(1.4em + 40px);
    margin-top: calc(0px - (1.4em + 40px));
    pointer-events: none;
  }
  .mobile-menu-back-item a:before {
    content: "";
    width: 14px;
    height: 12px;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowLeft.svg#default');
    background-size: 14px;
    margin-right: 10px;
    display: inline-block;
  }
  .mobile-menu-trigger:hover~ul {
    left: 0;
  }
  .menu-bar:hover {
    left: 0;
  }
  .menu-bar>li>[aria-haspopup="true"]:hover~ul {
    left: 0;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul {
    margin-top: calc(1.4em + 40px);
  }
  .menu-bar>li>[aria-haspopup="true"]~ul:hover {
    left: 0;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul [aria-haspopup="true"]:hover~ul {
    max-height: 500px;
    animation: dropdown 0.3s forwards;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul li:hover>[aria-haspopup="true"]~ul {
    max-height: 500px;
    transform: scaleY(1);
  }
  .menu-bar>li:hover~.mobile-menu-header a {
    visibility: hidden;
  }
}

@media all and (max-width: 950px) and (hover: none) {
  .mobile-menu-trigger:hover~ul {
    left: 0;
  }
  .menu-bar>li>[aria-haspopup="true"]:hover~ul {
    left: 0;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul:hover {
    left: 0;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul [aria-haspopup="true"]:hover~ul {
    max-height: 500px;
    animation: dropdown 0.3s forwards;
  }
  .menu-bar>li>[aria-haspopup="true"]~ul [aria-haspopup="true"]~ul:hover {
    max-height: 500px;
    transform: scaleY(1);
  }
  .menu-bar>li:hover~.mobile-menu-header a {
    visibility: hidden;
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes flyout {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

.carousel.carousel-slider .control-arrow {
  border-radius: 50%;
}